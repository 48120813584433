
import { CustomPage, Chart } from "../components"

const Dashboard = () => {


    return (
    
    <>

<CustomPage title="test">

<Chart />

</CustomPage>
    </>)

}

export default Dashboard